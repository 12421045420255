import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Navigate } from "react-router-dom";
import axios from "axios";
import DOMPurify from "dompurify";
import { useSearchContext } from "../../context/SearchContext";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import "./JobView.css";

function JobView() {
  const [job, setJob] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const { searchState } = useSearchContext();

  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchJob = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${API_URL}/api/jobs/${id}/`);
        setJob(response.data);
        setError(null);
      } catch (err) {
        if (err.response && err.response.status === 404) {
          setError("404");
        } else {
          setError("Failed to fetch job details. Please try again later.");
        }
        console.error("Error fetching job:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchJob();
  }, [id, API_URL]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);

  const handleBackClick = () => {
    if (searchState.lastSearchPage) {
      navigate(searchState.lastSearchPage);
    } else {
      navigate("/");
    }
  };

  function isValidEmail(value) {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(value);
  }

  const renderApplyButton = () => {
    if (!job) return null;
    if (job.apply_link) {
      return isValidEmail(job.apply_link) ? (
        <a
          className="apply-btn"
          target="_blank"
          rel="noopener noreferrer"
          href={`mailto:${job.apply_link}`}
        >
          Apply Now
        </a>
      ) : (
        <a
          href={job.apply_link}
          className="apply-btn"
          target="_blank"
          rel="noopener noreferrer"
        >
          Apply Now
        </a>
      );
    } else {
      return (
        <button
          className="apply-btn"
          onClick={() => alert("Please contact the company directly to apply.")}
        >
          Contact to Apply
        </button>
      );
    }
  };

  const shareUrl = window.location.href;
  const shareTitle = job ? `${job.title} at ${job.company}` : "";

  const shareViaEmail = () => {
    const subject = encodeURIComponent(
      `Job Opportunity: ${job.title} at ${job.company}`
    );
    const body = encodeURIComponent(`${shareTitle}\n${shareUrl}`);
    window.location.href = `mailto:?subject=${subject}&body=${body}`;
  };

  const shareViaX = () => {
    const arrow = "▼"; // Unicode 'black down-pointing triangle'
    window.open(
      `https://x.com/intent/tweet?text=${encodeURIComponent(
        ` ${shareTitle} | More details ${arrow}`
      )}&url=${encodeURIComponent(shareUrl)}`,
      "_blank"
    );
  };

  const shareViaFacebook = () => {
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        shareUrl
      )}`,
      "_blank"
    );
  };

  const shareViaLinkedIn = () => {
    window.open(
      `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
        shareUrl
      )}`,
      "_blank"
    );
  };

  if (loading) return <LoadingSpinner />;
  if (error === "404") return <Navigate to="/not-found" replace />;
  if (error) return <div className="error">{error}</div>;
  if (!job) return null;

  return (
    <div className="JobView">
      <button onClick={handleBackClick} className="back-link">
        ← Back to Jobs
      </button>
      <div className={`job-details ${loading ? "loading" : "loaded"}`}>
        <h2>{job.title}</h2>
        <p className="company">{job.company}</p>
        <p className="location">{job.location}</p>
        {job.salary && <p className="salary">Salary: {job.salary}</p>}
        <p className="deadline">
          <span className="calendar-icon">📅</span>
          Apply by:{" "}
          <strong>
            {job.deadline
              ? new Date(job.deadline).toLocaleDateString()
              : "Not specified"}
          </strong>
        </p>
        <div className="job-tags">
          {job.tags.map((tag, index) => (
            <span key={index} className="tag">
              {tag.name}
            </span>
          ))}
        </div>
        <h3>Job Description</h3>
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(job.description),
          }}
        />
        {job.person_specification ? (
          <>
            <h3>Person Specification</h3>
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(job.person_specification),
              }}
            />
          </>
        ) : (
          ""
        )}
        <p className="last-updated">
          Posted: {new Date(job.created_at).toLocaleDateString()}
        </p>

        {job.apply_link ? renderApplyButton() : ""}

        <div className="share-buttons">
          <h4>Share this job:</h4>
          <div className="share-buttons-container">
            <button onClick={shareViaEmail} className="share-btn email-btn">
              📧 Email
            </button>
            <button onClick={shareViaX} className="share-btn x-btn">
              𝕏
            </button>
            <button
              onClick={shareViaFacebook}
              className="share-btn facebook-btn"
            >
              Facebook
            </button>
            <button
              onClick={shareViaLinkedIn}
              className="share-btn linkedin-btn"
            >
              LinkedIn
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default JobView;
